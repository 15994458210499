import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import { useList } from "react-firebase-hooks/database";
import { get, db } from "./fire";

function App() {
  const [password, setPassword] = useState("");
  const [showData, setShowData] = useState(false);
  const [snapshots, loading, error] = useList(get());
  const [newInput, setNewInput] = useState(null);

  const pwCheck = () => {
    if (password === "dadadganteng") {
      setShowData(true);
    } else {
      alert("wrong password");
    }
  };

  const updateProfit = (flag) => {
    let currentValue = snapshots[0].val();
    if (flag === "profit") {
      db.set({ profit: currentValue + +newInput });
      // console.log();
    } else if (flag === "reset") {
      if (window.confirm("Are you sure you want to reset the data?")) {
        db.set({ profit: 0 });
      }
    } else {
      db.set({ profit: currentValue - newInput });
    }
    setNewInput(null);
  };

  return (
    !loading &&
    snapshots &&
    !error && (
      <>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <div className={showData ? "hide" : ""}>
              <p>Login Page</p>
              <p className="App-link">Enter Password:</p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  pwCheck();
                }}
              >
                <input
                  type="password"
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </form>
            </div>
            <div className={showData ? "" : "hide"}>
              <p>Current Profit</p>
              <h2>${snapshots[0].val()}</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  pwCheck();
                }}
              >
                <input
                  type="number"
                  placeholder="input number"
                  value={newInput}
                  onChange={(e) => setNewInput(e.target.value)}
                ></input>
                <br />
                <button
                  onClick={() => updateProfit("profit")}
                  style={{
                    color: "white",
                    backgroundColor: "#0080ff",
                    borderRadius: "10px",
                    padding: "15px",
                    margin: "5px",
                    border: "none",
                    fontSize: "1rem",
                  }}
                >
                  Profit
                </button>
                <button
                  onClick={updateProfit}
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: "10px",
                    padding: "15px",
                    margin: "5px",
                    border: "none",
                    fontSize: "1rem",
                  }}
                >
                  Loss
                </button>
                <br />
                <button
                  onClick={() => updateProfit("reset")}
                  style={{
                    color: "white",
                    backgroundColor: "gold",
                    borderRadius: "10px",
                    padding: "15px",
                    margin: "5px",
                    border: "none",
                    fontSize: "1rem",
                  }}
                >
                  Reset
                </button>
              </form>
            </div>
          </header>
        </div>
      </>
    )
  );
}

export default App;
